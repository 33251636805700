<template>
  <div class="container" v-if="initOk">
    <van-form @submit="onSubmit">
      <p class="input-info">预估到校时间</p>
      <van-field
        readonly
        clickable
        name="visitTimeStr"
        :value="visitTimeStr"
        placeholder="请选择"
        @click="showVisitTimePicker = true"
        :rules="[{ required: true, message: '请选择预估到校时间' }]"
      />
      <van-popup v-model="showVisitTimePicker" position="bottom">
        <van-datetime-picker
          type="datehour"
          :min-date="new Date()"
          :min-hour="8"
          :max-hour="22"
          :formatter="formatter"
          @confirm="onVisitTimeConfirm"
          @cancel="showVisitTimePicker = false"
        />
      </van-popup>
      <p class="input-info">
        来访人数
        <!-- <span style="color: #aaa">
          （温馨提示：因疫情防控需要，陪同人员限制1人进入校门且需全程佩戴一次性医用口罩，请各位新生和家长合理安排出行，避免造成因疫情无法进校带来的麻烦！）
        </span> -->
      </p>
      <van-field
        v-model="no"
        name="no"
        type="digit"
        placeholder="请输入来访人数（1-2人）"
        :rules="[{ required: true, message: '请输入来访人数（1-2人）' }]"
      />
      <p class="input-info">交通工具</p>
      <van-field
        readonly
        clickable
        name="traffic"
        :value="traffic"
        placeholder="请选择"
        @click="showTrafficPicker = true"
        :rules="[{ required: true, message: '请选择您的交通工具' }]"
      />
      <van-popup v-model="showTrafficPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="trafficColumns"
          @confirm="onTrafficConfirm"
          @cancel="showTrafficPicker = false"
        />
      </van-popup>
      <p class="input-info">抵达站点</p>
      <van-field
        readonly
        clickable
        name="station"
        :value="station"
        placeholder="请选择"
        @click="showStationPicker = true"
        :rules="[{ required: true, message: '请选择您的抵达站点' }]"
      />
      <van-popup v-model="showStationPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="stationColumns"
          @confirm="onStationConfirm"
          @cancel="showStationPicker = false"
        />
      </van-popup>

      <!-- <p class="input-info">是否有健康码</p>
      <van-field
        readonly
        clickable
        name="isHaveHealthCode"
        :value="isHaveHealthCode"
        placeholder="请选择"
        @click="showIsHaveHealthCodePicker = true"
        :rules="[{ required: true, message: '请选择您的抵达站点' }]"
      />
      <van-popup v-model="showIsHaveHealthCodePicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="['是', '否']"
          @confirm="
            (value) => {
              this.isHaveHealthCode = value;
              this.showIsHaveHealthCodePicker = false;
            }
          "
          @cancel="showIsHaveHealthCodePicker = false"
        />
      </van-popup> -->

      <p class="input-info">当前居住地</p>
      <van-field
        v-model="curLiveAddress"
        name="curLiveAddress"
        placeholder="您的当前居住地"
        :rules="[
          { required: true, message: '请输入您的当前居住地（精确到门牌号）' },
        ]"
      />

      <!-- <p class="input-info">到校前14天内，是否到过国内疫情中高风险地区</p>
      <van-field
        readonly
        clickable
        name="highRiskInfoBoolean"
        :value="highRiskInfoBoolean"
        placeholder="请选择"
        @click="showHighRiskInfoBooleanPicker = true"
        :rules="[
          {
            required: true,
            message: '请选择到校前14天内，是否到过国内疫情中高风险地区',
          },
        ]"
      />
      <van-popup v-model="showHighRiskInfoBooleanPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="['是', '否']"
          @confirm="
            (value) => {
              this.highRiskInfoBoolean = value;
              this.showHighRiskInfoBooleanPicker = false;
            }
          "
          @cancel="showHighRiskInfoBooleanPicker = false"
        />
      </van-popup>

      <p class="input-info" v-if="highRiskInfoBoolean == '是'">
        请如实填写到校前14天内，到过的国内疫情中高风险地区时间及详细地址
        <span style="color: #aaa">
          （例：2021年8月15日-2021年8月20日从广西南宁到新疆乌鲁木齐市xxxx）
        </span>
      </p>
      <van-field
        v-if="highRiskInfoBoolean == '是'"
        v-model="highRiskInfo"
        name="highRiskInfo"
        placeholder="请输入"
        :rules="[
          {
            required: true,
            message:
              '请如实填写到校前14天内，到过的国内疫情中高风险地区时间及详细地址',
          },
        ]"
      />

      <p class="input-info">到校前14天内，是否有国（境）外旅居史</p>
      <van-field
        readonly
        clickable
        name="isHaveForeignLived"
        :value="isHaveForeignLived"
        placeholder="请选择"
        @click="showIsHaveForeignLivedPicker = true"
        :rules="[
          {
            required: true,
            message: '请选择到校前14天内，是否有国（境）外旅居史',
          },
        ]"
      />
      <van-popup v-model="showIsHaveForeignLivedPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="['是', '否']"
          @confirm="
            (value) => {
              this.isHaveForeignLived = value;
              this.showIsHaveForeignLivedPicker = false;
            }
          "
          @cancel="showIsHaveForeignLivedPicker = false"
        />
      </van-popup>

      <p class="input-info">到校前14天内，是否有接触国（境）外人员</p>
      <van-field
        readonly
        clickable
        name="isHaveForeignPeople"
        :value="isHaveForeignPeople"
        placeholder="请选择"
        @click="showIsHaveForeignPeoplePicker = true"
        :rules="[
          {
            required: true,
            message: '请选择到校前14天内，是否有接触国（境）外人员',
          },
        ]"
      />
      <van-popup v-model="showIsHaveForeignPeoplePicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="['是', '否']"
          @confirm="
            (value) => {
              this.isHaveForeignPeople = value;
              this.showIsHaveForeignPeoplePicker = false;
            }
          "
          @cancel="showIsHaveForeignPeoplePicker = false"
        />
      </van-popup>

      <p class="input-info">到校前14天内，是否有发热、乏力、咳嗽、鼻塞等症状</p>
      <van-field
        readonly
        clickable
        name="healthInfo"
        :value="healthInfo"
        placeholder="请选择"
        @click="showHealthInfoPicker = true"
        :rules="[
          {
            required: true,
            message: '请选择到校前14天内，是否有发热、乏力、咳嗽、鼻塞等症状',
          },
        ]"
      />
      <van-popup v-model="showHealthInfoPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="['是', '否']"
          @confirm="
            (value) => {
              this.healthInfo = value;
              this.showHealthInfoPicker = false;
            }
          "
          @cancel="showHealthInfoPicker = false"
        />
      </van-popup> -->

      <p class="input-info">备注信息</p>
      <van-field
        name="memo"
        v-model="memo"
        rows="2"
        autosize
        type="textarea"
        placeholder="请输入备注信息"
      />

      <div style="margin: 16px">
        <van-button v-if="id" round block type="default" native-type="submit"
          >修改</van-button
        >
        <van-button v-else round block type="danger" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Notify } from "vant";
import * as api from "@/apis/api";
export default {
  data() {
    return {
      initOk: false,
      id: undefined,
      visitTime: undefined,
      visitTimeStr: undefined,
      showVisitTimePicker: false,
      no: undefined,
      traffic: "",
      trafficColumns: [
        "飞机",
        "火车（含高铁和动车）",
        "客运汽车",
        "自驾",
        "公共汽车",
      ],
      showTrafficPicker: false,

      station: "",
      stationColumns: [
        "南宁东站（优选）",
        "南宁站",
        "埌东汽车站",
        "江南客运站",
        "西乡塘客运站",
        "金桥客运站",
        "扶绥汽车站",
        "扶绥火车站",
        "直达学校",
      ],
      showStationPicker: false,

      isHaveHealthCode: "",
      showIsHaveHealthCodePicker: false,

      curLiveAddress: undefined,

      highRiskInfo: "",
      highRiskInfoBoolean: undefined,
      showHighRiskInfoBooleanPicker: false,

      isHaveForeignLived: undefined,
      showIsHaveForeignLivedPicker: false,

      isHaveForeignPeople: undefined,
      showIsHaveForeignPeoplePicker: false,

      healthInfo: undefined,
      showHealthInfoPicker: false,

      memo: undefined,
    };
  },
  created() {
    this.findVisit();
  },
  methods: {
    onVisitTimeConfirm(time) {
      this.visitTime = this.dateFormat("YYYY-mm-dd H:00:00", time);
      this.visitTimeStr = this.dateFormat("YYYY年mm月dd日,H时", time);
      this.showVisitTimePicker = false;
    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      } else if (type === "hour") {
        return `${val}时`;
      }
      return val;
    },

    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },

    onTrafficConfirm(value) {
      this.traffic = value;
      this.showTrafficPicker = false;
    },

    onStationConfirm(value) {
      this.station = value;
      this.showStationPicker = false;
    },

    findVisit() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      api.visit().then((res) => {
        if (res.id) {
          this.id = res.id;
          this.visitTime = res.visitTime;
          this.visitTimeStr = this.dateFormat(
            "YYYY年mm月dd日,H时",
            new Date(res.visitTime.replaceAll("-", "/"))
          );
          this.no = res.no;
          this.traffic = res.traffic;
          this.station = res.station;
          this.isHaveHealthCode = res.isHaveHealthCode;
          this.curLiveAddress = res.curLiveAddress;
          this.highRiskInfo = res.highRiskInfo;
          if (this.highRiskInfo.length > 0) {
            this.highRiskInfoBoolean = "是";
          } else {
            this.highRiskInfoBoolean = "否";
          }
          this.isHaveForeignLived = res.isHaveForeignLived;
          this.isHaveForeignPeople = res.isHaveForeignPeople;
          this.healthInfo = res.healthInfo;
          this.memo = res.memo;
        }
        Toast.clear();
        this.initOk = true;
      });
    },
    onSubmit(values) {
      values.visitTime = this.visitTime;
      if (values.highRiskInfoBoolean == "否") {
        values.highRiskInfo = "";
      }
      api.insertVisit(values).then((res) => {
        if (res.state == "ok") {
          // Toast.success("提交成功");
          // this.findVisit();
          this.$router.push({ path: "/checkin", query: {} });
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 0px 0px 10px 0px;
}
.input-info {
  padding: 10px;
  margin: 0;
  font-size: 14px;
}
</style>